import { createContext, useState, useEffect } from "react";
import useFetch from '../api/useFetch';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {



  const [notifications, setNotifications] = useState({to_buy_count: 0, to_pickup_count: 0, to_print_count: 0, to_ship_count: 0, total: 0})
  const [NotificationRefresh, setNotificationRefresh] = useState(false);


  let { result } = useFetch(`/notifications`, NotificationRefresh);

  useEffect(() => {
    setNotifications(result);
  }, [result]);

    useEffect(() => {
      const interval = setInterval(() => {
        setNotificationRefresh(!NotificationRefresh);
      }, 300000); // 5 minutes in milliseconds
  
      return () => clearInterval(interval);
    }, [NotificationRefresh]);

  return (
    <NotificationsContext.Provider value={{ notifications, NotificationRefresh, setNotificationRefresh}}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
