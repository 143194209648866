import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import API_BASE_URL from './config';

const useFetch = (url, dependencies) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout } = useContext(AuthContext);

  const isIterable = (obj) => {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem('accessToken');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        console.log(`${API_BASE_URL}${url}`)
        const response = await axios.get(`${API_BASE_URL}${url}`, {
          cancelToken: source.token,
          headers,
        });

        if (!response.status === 200) {
          throw Error('Could not fetch data for that resource');
        }

        setData(response.data);
        setIsLoading(false);
        setError(null);
        console.log(response.data)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else if (error.response && error.response.status === 401) {
          // Refresh token logic
          const refreshToken = localStorage.getItem('refreshToken');
          try {
            const refreshResponse = await axios.post(`${API_BASE_URL}/token/refresh/`, { refresh: refreshToken });
            if (refreshResponse.status === 200) {
              localStorage.setItem('accessToken', refreshResponse.data.access);
              return fetchData();
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              logout();
            }
          }
        } else {
          setError(error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      source.cancel('Component unmounted, request canceled');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url,logout, ...(isIterable(dependencies) ? dependencies : [dependencies])]);

  return { result: data, isLoading, error };
};

export default useFetch;
