import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './Loading';
import '../css/main.css';
import { getCurrentSeason } from '../utils/getCurrentSeason';
import Table from './Table';

const League = () => {

    const currentSeason = getCurrentSeason();
    const { id } = useParams();
    const [season, setSeason] = useState(currentSeason)
    const [tab, setTab] = useState("Fixtures")
    const [fixture, setFixture] = useState(1)
    const { result: league, isLoading: leagueLoading, error: leagueError } = useFetch(`/league/${id}?season=${season}`,[season]);
    const [playerStat, setPlayerStat] = useState("Goals")
    const [standingTableContent, setStandingTableContent] = useState([])
    const [fixturesTableContent, setFixturesTableContent] = useState([])
    const [playersStatsTableContent, setPlayersStatsTableContent] = useState([])
    const handleSeasonChange = (event) => {
        const selectedSeason = event.target.value;
        setSeason(selectedSeason);
      };

      useEffect(() => {
        if (league && league.games && league.games.length > 0) {
            const currentDate = new Date();
            const latestPastFixture = league.games.reduce((latest, game) => {
                const gameDate = new Date(game.date);
                const fixtureNumber = parseInt(game.fixture);
                if (gameDate < currentDate && fixtureNumber > latest) {
                    return fixtureNumber;
                }
                return latest;
            }, 0);
            setFixture(latestPastFixture || 1);
        }
    }, [league]);

    
    const fixturesTableHeader = [
        { content: "#", size: "sm", isLink: false },
        { content: "Date", size: "md", isLink: false },
        { content: "Time", size: "md", isLink: false },
        { content: "Home", size: "md", isLink: true, link: 'team' },
        { content: "Away", size: "md", isLink: true, link: 'team' },
        { content: "Lineups", size: "sm", isLink: false },
        { content: "Status", size: "md", isLink: false },
        { content: "Score", size: "sm", isLink: true, link: 'game' },
        { content: "Xwin", size: "sm", isLink: false },
      ];


    const standingTableHeader = [
        { content: "#", size: "sm", isLink: false },
        { content: "Team", size: "md", isLink: true, link: 'team' },
        { content: "P", size: "sm", isLink: false },
        { content: "G", size: "sm", isLink: false },
        { content: "W", size: "sm", isLink: false },
        { content: "D", size: "sm", isLink: false },
        { content: "L", size: "sm", isLink: false },
        { content: "GF", size: "sm", isLink: false },
        { content: "GA", size: "sm", isLink: false },
        { content: "GD", size: "sm", isLink: false },
      ];

      const playersStatsTableHeader = [
        { content: "Player", size: "md", isLink: true, link: 'player' },
        { content: "Team", size: "md", isLink: true, link: 'team' },
        { content: "Total", size: "sm", isLink: false },
      ];

      useEffect(() => {
        if (league !== null) {
          setStandingTableContent(
            league.standings.map((standing) => 
                [standing.rank,
                [standing.team.name,standing.team.id],
                standing.points,
                standing.win+standing.draw+standing.lose,
                standing.win,
                standing.draw,
                standing.lose,
                standing.goals_for,
                standing.goals_against,
                standing.goals_for - standing.goals_against
            ])
          );
          setFixturesTableContent(
            league.games.filter(game => parseInt(game.fixture) === fixture).map((game) => 
                [game.fixture,
                game.date,
                game.time,
                [game.home_team.name,game.home_team.id],
                [game.away_team.name,game.away_team.id],
                game.has_lineups,
                game.status,
                [game.status === "Match Finished" ? `${game.goals_home_et + game.goals_home_ft} - ${game.goals_away_et + game.goals_away_ft}` : ' --- ',game.id],
                game.win_prediction,
            ])
        );
        setPlayersStatsTableContent(
            league.player_stats.filter(player_stat => player_stat.stat === playerStat && parseInt(player_stat.value) > 1).map((player_stat) => 
                [[player_stat.player.name,player_stat.player.id],
                [player_stat.player.current_team.name,player_stat.player.current_team.id],
                parseInt(player_stat.value),
            ])
        );
        }}, [league, fixture, playerStat, season, tab]);

  return (
        <>
            {leagueLoading ? (
                <Loading />
            ) : league && !leagueLoading && !leagueError &&
                (<>
                    <div className="content">
                        <div className="header">
                            <img className="header-logo" src={league.logo_url} alt={league.name} />
                            <br />
                            <select onChange={handleSeasonChange} name="seasons" id="seasons" value={season}>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                            </select>   
                        </div>
                    </div>
                    <div className="container">
                    <div className="tabs-container">
                        <div onClick={()=>setTab("Fixtures")} class={`tab ${tab === "Fixtures" ? "active" : ""} `}>Fixtures</div>
                        <div onClick={()=>setTab("Standing")} class={`tab ${tab === "Standing" ? "active" : ""} `}>Standing</div>
                        <div onClick={()=>setTab("Players")} class={`tab ${tab === "Players" ? "active" : ""} `}>Players Stats</div>
                    </div>
                    {tab === "Standing" &&
                    <>
                    {league && !leagueLoading && !leagueError && <Table tableHeader={standingTableHeader} tableContent={standingTableContent} />}
                    {leagueLoading && <Loading />}
                    {leagueError && <>{leagueError} - Please contact Florent</>}
                    </>}
                    {tab === "Fixtures" &&
                    <>
                    <div className="buttons"><div onClick={() => setFixture(fixture - 1)} className={`button ${fixture === 1 ? "active" : ""}`}>Previous</div><div onClick={() => setFixture(fixture + 1)} className={`button ${fixture === league.games.length ? "active" : ""}`}>Next</div></div>
                    {league && !leagueLoading && !leagueError && <Table tableHeader={fixturesTableHeader} tableContent={fixturesTableContent} />}
                    {leagueLoading && <Loading />}
                    {leagueError && <>{leagueError} - Please contact Florent</>}
                    </>}
                    {tab === "Players" &&
                    <>
                    <div className="buttons"><div onClick={() => setPlayerStat("Goals")} className={`button ${playerStat === "Goals" ? "active" : ""}`}>GOALS</div><div onClick={() => setPlayerStat("Assists")} className={`button ${playerStat === "Assists" ? "active" : ""}`}>ASSISTS</div><div onClick={() => setPlayerStat("Penalties Scored")} className={`button ${playerStat === "Penalties Scored" ? "active" : ""}`}>PENALTIES</div></div>
                    {league && !leagueLoading && !leagueError && <Table tableHeader={playersStatsTableHeader} tableContent={playersStatsTableContent} />}
                    {leagueLoading && <Loading />}
                    {leagueError && <>{leagueError} - Please contact Florent</>}
                    </>}
                    </div>
                </>
            )}
        </>
    )
}


export default League;