import React from 'react';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../css/main.css'; 
import SidebarItem from './SidebarItem';
import SidebarContext from '../context/SidebarContext';


const Sidebar = () => {
    const [selectedItem, setSelectedItem] = useState('HOME');
    const { collapsed, toggleCollapsed } = useContext(SidebarContext);

    const menuItems = [
        { icon: 'fa-home', text: 'HOME', to: '/' },
        { icon: 'fa-trophy', text: 'LEAGUES', to: '/leagues' },
        { icon: 'fa-users', text: 'TEAMS', to: '/teams' },
        { icon: 'fa-user', text: 'PLAYERS', to: '/players' },
        { icon: 'fa-futbol', text: 'GAMES', to: '/' },
        { icon: 'fas fa-chart-line', text: 'METRICS', to: '/' },
        { icon: 'fas fa-code', text: 'SCRIPTS', to: '/' },
      ];

      const handleItemClick = (text) => {
        setSelectedItem(text);
      };

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        <i className="fas fa-bars sidebar-btn" onClick={toggleCollapsed}></i>
        <div className={`sidebar-content ${collapsed ? 'collapsed' : ''}`}>
        {menuItems.map((item) => (
          <SidebarItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            to={item.to}
            isSelected={selectedItem === item.text}
            onClick={() => handleItemClick(item.text)}
          />
        ))}
        </div>
        <div className="sidebar-bottom">
            <Link to="/">
            <i className="fas fa-cog"></i>
            </Link>
        </div>
        </div>
    );
};

export default Sidebar;
