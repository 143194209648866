import useFetch from '../api/useFetch';
import { useState } from 'react';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import '../css/main.css';


const Leagues = () => {
    const [continent, setContinent] = useState("Champion_Europe")
    const { result: leagues, isLoading: leaguesLoading } = useFetch(`/current_leagues?continent=${continent}`,[continent]);
    
  return (
    <>
    <div class="container">
    <div class="tabs-container">
      <div onClick={() => setContinent("Champion_Europe")} class={continent === "Champion_Europe" ? 'tab active' : 'tab'}>Champion Europe</div>
      <div onClick={() => setContinent("Challenger_Europe")} class={continent === "Challenger_Europe" ? 'tab active' : 'tab'}>Challenger Europe</div>
      <div onClick={() => setContinent("Second_Division")} class={continent === "Second_Division" ? 'tab active' : 'tab'}>Second Division</div>
      <div onClick={() => setContinent("Asia")} class={continent === "Asia" ? 'tab active' : 'tab'}>Asia</div>
      <div onClick={() => setContinent("America")} class={continent === "America" ? 'tab active' : 'tab'}>America</div>
      <div onClick={() => setContinent("Cup")} class={continent === "Cup" ? 'tab active' : 'tab'}>Cup</div>
    </div>
    <div className="content">
  {leaguesLoading ? (
    <Loading />
  ) : leagues && leagues.length > 0 ? (
    leagues.map((league) => (
        <Link to={`/league/${league.api_id}`}>
      <div className="header" key={league.api_id}>
        <img className="header-logo" src={league.logo_url} alt={league.name} />
      </div>
      </Link>
    ))
  ) : (
    <div>No leagues available at this time.</div>
  )}
</div>
  </div>
</>
  )
}

export default Leagues;