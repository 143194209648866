import { createContext, useState } from "react";

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const initialState = {
        accessToken: localStorage.getItem("accessToken") || null,
        refreshToken: localStorage.getItem("refreshToken") || null,
        firstName: localStorage.getItem("firstName") || null,
        lastName: localStorage.getItem("lastName") || null,
        profilePictureURL: localStorage.getItem("profilePictureURL") || null,
      };
    const [auth,setAuth] = useState(initialState)

    const logout = () => {
        setAuth({ accessToken: null });
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
      };

    return (
        <AuthContext.Provider value={{auth, setAuth, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext