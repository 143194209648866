import { createContext, useState } from 'react';

const SearchTermContext = createContext();

export const SearchTermProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState([]);

  const clearSearchTerm = () => {
    setSearchTerm([]);
  };

  return (
    <SearchTermContext.Provider
      value={{ searchTerm, setSearchTerm, clearSearchTerm }}
    >
      {children}
    </SearchTermContext.Provider>
  );
};

export default SearchTermContext;
