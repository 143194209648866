import { Link } from "react-router-dom";

const GameLine = ( {game, showDate, showLeague, showFixture} ) => {
    const gameTime = new Date(game.date + ' ' + game.time);
    gameTime.setHours(gameTime.getHours() - 4); // Subtract 4 hours
    const formattedTime = gameTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  return (
    <div className="line" key={game.id}>
        {showFixture && <div className="cell">{game.fixture}</div>}
        {showLeague && <div className="md-cell">{<Link to={`/league/${game.league.api_id}`}>{game.league.name}</Link>}</div>}
        {showDate && <div className="cell">{game.date}</div>}
        <div className="cell">{formattedTime}</div>
        {game.home_team && (
        <div className="md-cell"><Link to={`/team/${game.home_team.id}`}>{game.home_team.name}{game.home_team.has_top_striker && <i class="green fas fa-futbol"></i>}{game.home_team.is_losing_team && <i class="red fas fa-stop"></i>}{game.home_team.is_winning_team && <i class="green fas fa-stop"></i>}</Link></div>)}
        {!game.home_team && (
        <div className="md-cell">Unknown</div>)}
        {game.away_team && (
        <div className="md-cell"><Link to={`/team/${game.away_team.id}`}>{game.away_team.name}{game.away_team.has_top_striker && <i class="green fas fa-futbol"></i>}{game.away_team.is_losing_team && <i class="red fas fa-stop"></i>}{game.away_team.is_winning_team && <i class="green fas fa-stop"></i>}</Link></div>)}
        <div className="cell">{game.has_lineups ? <i class="fas fa-tshirt"></i> : ''}</div>
        {!game.away_team && (
        <div className="md-cell">Unknown</div>)}
        
        {game.status === 'Match Finished' &&
        <div className="cell"><Link to={`/game/${game.id}`}>{game.goals_home_et + game.goals_home_ft} - {game.goals_away_et + game.goals_away_ft}</Link></div>}
        {(game.status.includes("Half") || game.status.includes("Extra") || game.status.includes("Match Interrupted") || game.status.includes("Penalty in progress")) &&
        <div className="cell live"><Link to={`/game/${game.id}`}>{(game.goals_home_et + game.goals_home_ft) || game.goals_home_ht} - {(game.goals_away_et + game.goals_away_ft) ||game.goals_away_ht} {game.status.includes("Match Interrupted") && <small>INT</small>}{game.status.includes("Penalty in progress") && <small>PEN</small>}</Link></div>}
        {(game.status === 'Not Started' || game.status === 'Time to be defined' || game.status === 'Match Postponed') &&
        <div className="cell"><Link to={`/game/${game.id}`}><i className="fas fa-eye"></i></Link></div>}
        {(game.status === 'Match Abandoned') &&
        <div className="cell"><Link to={`/game/${game.id}`}>ABB</Link></div>}
        <div className="cell">{game.win_prediction}</div>
        <div className="cell">{game.preview_win_stats && parseFloat(game.preview_win_stats).toFixed(2)}</div>
        <div className="cell">{game.preview_win_stats && game.win_prediction && game.preview_prediction_win_diff}</div>
        <div className="cell">{game.home_goals_preview && game.away_goals_preview && `${parseFloat(game.home_goals_preview).toFixed(1)} - ${parseFloat(game.away_goals_preview).toFixed(1)}`}</div>
    </div>
  )
}

export default GameLine