import useFetch from "../api/useFetch";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { useState } from "react";

const Players = () => {
    const { result: strikers, isLoading: strikersLoading } = useFetch('/players',[]);
    const [goalsPerStarterSort, setGoalsPerStarterSort] = useState(null);


    const statProcess = (player, stat_name) => {
        return player.player_stats.filter(stat => (stat.stat === stat_name && stat.team === player.current_team.id)).reduce((sum, stat) => sum + parseInt(stat.value), 0)
      }

      const handleSort = (column) => {
        if (column === "goalsPerStarter") {
            const sortedArray = [...strikers.players].sort((a, b) => {
                const goalsPerStarterA = statProcess(a, "Starter") === 0 ? 0 : statProcess(a, "Goals") / statProcess(a, "Starter");
                const goalsPerStarterB = statProcess(b, "Starter") === 0 ? 0 : statProcess(b, "Goals") / statProcess(b, "Starter");
                return goalsPerStarterSort === "asc"
                    ? goalsPerStarterA - goalsPerStarterB
                    : goalsPerStarterB - goalsPerStarterA;
            });
            strikers.players = sortedArray; // Update the current_players array
            setGoalsPerStarterSort(goalsPerStarterSort === "asc" ? "desc" : "asc");
        }
        // Add other sorting logic for other columns here if needed
    };

  return (
    strikersLoading ? (
        <Loading />
      )  :
    // players && players.players.map((player) => <>{player.name}<br/></>)
    <div className="content">
    <div className="container">

    <div className="table">
        <div className="table-header">
            <div className="md-cell">Name</div>
            <div className="md-cell">Team</div>
            <div className="cell">Starter</div>
            <div className="cell">Goals</div>
            <div className="cell">Penalties</div>
            <div className="cell sortable" onClick={() => handleSort("goalsPerStarter")}>Goals per starter</div>
        </div>
        <div className="table-content">
            {!strikersLoading &&
                strikers && strikers.players.length > 0 &&
                strikers.players.map((player) => (
                    
                    <div className='line'>
                    <Link to={`/player/${player.id}`}>
                    <div className="md-cell">{player.name}</div>
                    </Link>
                    <Link to={`/team/${player.current_team.id}`}>
                    <div className="md-cell">{player.current_team.name}</div>
                    </Link>
                    <div className="cell">{statProcess(player, "Starter")}</div>
                    <div className="cell">{statProcess(player, "Goals")}</div>
                    <div className="cell">{statProcess(player, "Penalties Scored")}</div>
                    <div className="cell">{statProcess(player, "Goals") === 0 ? 0 : (statProcess(player, "Goals") / statProcess(player, "Starter")).toFixed(2)}</div>
                    </div>
                ))
            }
        </div>
    </div>
    </div>
    </div>
  )
}

export default Players