import AuthContext from "./context/AuthProvider";
import {SidebarProvider} from "./context/SidebarContext";
import Main from "./components/Main";
import { useContext } from "react";
import { ThemeContext } from "./context/ThemeContext";
import {NotificationsProvider} from './context/NotificationsContext';


function App() {
  const { auth } = useContext(AuthContext);
  const { theme } =  useContext(ThemeContext);
  return (
    <SidebarProvider>
    <NotificationsProvider>
    <main className={`App ${theme}`}>
      {auth.accessToken ? (
        <Main />
      ) : (
        <Main />
      )}
    </main>
    </NotificationsProvider>
    </SidebarProvider>
  );
}

export default App;
