import LoadingGif from '../static/img/loading.gif';

function Loading() {
  return (
    <div className="loading">
    <img width="50" height="50" src={LoadingGif} alt="" />
    </div>
  )
}

export default Loading