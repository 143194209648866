// src/utils/getCurrentSeason.js

export function getCurrentSeason() {
    const today = new Date();
    const cutoffDate = new Date(today.getFullYear(), 5, 1); // June 1st

    if (today < cutoffDate) {
        return today.getFullYear() - 1;
    } else {
        return today.getFullYear();
    }
}
