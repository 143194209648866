import React, { useState, useEffect } from "react";
import TableLine from "./TableLine";

const Table = ({ tableHeader, tableContent }) => {
  const [sortedContent, setSortedContent] = useState(tableContent);
  const [sortConfig, setSortConfig] = useState({ index: null, direction: 'ascending' });

  useEffect(() => {
    setSortedContent(tableContent);
  }, [tableContent]);

  const handleSort = (index) => {
    let direction = 'ascending';
    if (sortConfig.index === index) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }

    const sortedData = [...sortedContent].sort((a, b) => {
      if (a[index] < b[index]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[index] > b[index]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedContent(sortedData);
    setSortConfig({ index, direction });
  };

  return (
    <div className="generic-table">
      <div className="table-header">
        {tableHeader.map((element, idx) => (
          <div
            key={element.content}
            className={`${element.size} 'sortable'}`}
            onClick={() => handleSort(idx)}
            style={{ cursor: 'pointer' }}
          >
            {element.content} 
            {sortConfig.index === idx ? (
              sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
            ) : ''}
          </div>
        ))}
      </div>
      <div className="table-body">
        {sortedContent && sortedContent.map((item, itemIdx) => (
          <div key={itemIdx} className="table-row">
            <TableLine header={tableHeader} content={item} id={itemIdx} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;