import useFetch from '../api/useFetch';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../css/main.css';
import Loading from './Loading';


const Coach = () => {
    const { id } = useParams();
    const [tab, setTab] = useState("Fixtures")
    const { result: coach, isLoading: coachLoading } = useFetch(`/coach/${id}`,[]);

  return (
   <>
            {coachLoading ? (
                <Loading />
            ) : coach && (
                <>
                <div className="content">
                    <div className="header">
                        <div className="info">
                            <center>
                            <b>Team</b><br />
                            <br />
                            <br />
                        <b>Country</b><br />
                        <br />
                        <br />
                        <b>League</b><br />
                        </center>
                        </div>
                    </div>
                    <div className="header">
                        <img className="header-logo" src={coach.photo_url} alt={coach.name} />
                        <div className="header-title">{coach.name}</div>
                    </div>
                    <div className="header">
                        <div className="info">
                            <center>
                            <b>Position</b><br />
                            <br />
                            <br />
                        <b>Age</b><br />{coach.age}
                        <br />
                        <br />
                        <b>Nationality</b><br />{coach.nationality && coach.nationality.name}
                        </center>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="tabs-container">
                        <div onClick={()=>setTab("Fixtures")} class={`tab ${tab === "Fixtures" ? "active" : ""} `}>Fixtures</div>
                        <div onClick={()=>setTab("Ranking")} class={`tab ${tab === "Ranking" ? "active" : ""} `}>Ranking</div>
                        <div onClick={()=>setTab("Stats")} class={`tab ${tab === "Stats" ? "active" : ""} `}>Stats</div>
                    </div>
                </div>
                </>
                )
            }
    </>
  )
}

export default Coach;