import { Link } from "react-router-dom";

const TableLine = ({ header, content, id }) => {
  return (
    <>
      {content &&
        content.length > 0 &&
        content.map((item, index) => (
            <>
            {!(typeof item === 'boolean') && !header[index].isLink && <div className= {header[index].size} key={index}>{item}</div>}
            {!(typeof item === 'boolean') && header[index].isLink && header[index].content !== "Details" && <div className= {header[index].size} key={index}><Link to={`/${header[index].link}/${item[1]}`}>{item[0]}</Link></div>}
            {!(typeof item === 'boolean') && header[index].isLink && header[index].content === "Details" && <div className= {header[index].size} key={index}><Link to={`/${header[index].link}/${item}`}><i class="fas fa-eye"></i></Link></div>}
            {typeof item === 'boolean' && item === true && <div className={header[index].size}><i style={{color: 'green'}} class="fas fa-check"></i></div>}
            {typeof item === 'boolean' && item === false && <div className={header[index].size}><i style={{color: 'red'}} class="fas fa-times"></i></div>}
          </>
        ))}
    </>
  );
};

export default TableLine;
